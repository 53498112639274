<template>
  <base-section
    id="affiliates"
    class="grey lighten-4"
    space="36"
  >
    <v-container>
      <base-section-heading :title="translations.trustedUs" />

      <v-slide-group>
        <template
          v-for="trustedLogo in trustedLogos"
        >
          <v-slide-item
            :key="trustedLogo"
            class="align-self-center"
          >
            <base-img
              :src="require(`@/assets/${trustedLogo}`)"
              color="grey"
              contain
              height="80"
              width="256"
            />
          </v-slide-item>

          <v-responsive
            v-if="trustedLogo != trustedLogos[trustedLogos.length - 1]"
            :key="`divider-${trustedLogo}`"
            class="text-center"
            height="56"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
  </base-section>
</template>

<script>
  import { STORE_NAMESPACE_ALL } from '@/App.vue'
  import { mapState } from 'vuex'
  export default {
    name: 'SectionTrustedUs',
    computed: {
      ...mapState(STORE_NAMESPACE_ALL, [
        'translations',
      ]),
    },
    data: () => ({
      trustedLogos: [
        'orlen_logo.png',
        'lotos_logo.png',
        'pulawy_logo.png',
      ],
    }),
  }
</script>
